<template>
  <div>
    <!-- adminユーザのみ使用可能 -->
    <div
      v-if="userRole === 'admin'"
      class="create-project-page pb-8 bg-purple-bg"
    >
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↓「戻る」ボタン 行 ↓ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <button
        class="text-sm mb-8 cursor-pointer text-white"
        @click="() => $router.push('/manage/categories')"
      >
        &lt;&lt;&nbsp;{{ $t('BACK_BTN') }}
      </button>
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- ↑「戻る」ボタン 行 ↑ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <!--「カテゴリ・プロジェクト・シリーズ・エディションの編集」-->
      <!-- --------+---------+---------+---------+---------+---------+------ -->
      <section class="text-3xl mb-20 font-semibold text-white">
        {{ $t('CATEGORY_EDIT_TITLE') }}
      </section>
      <ValidationObserver ref="observer">
        <form @submit.prevent="onSubmitForm">
          <section class="project-info">
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓「ページ種類」セクション ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              class="!mb-0"
              title="TYPE_OF_PAGE"
              :dataSource="form"
              :columns="arrayType"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ種類：種類 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="category_type">
                <a-select
                  :value="form.type"
                  @change="onTypeOfCategoryChange"
                  class="w-80"
                >
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                    v-for="typeOfCategory in typesOfCategory"
                    :value="typeOfCategory.value"
                    :key="typeOfCategory.value"
                  >
                    {{ typeOfCategory.key }}
                  </a-select-option>
                </a-select>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ種類：種類 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ種類：一覧表示 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="show_nftlist">
                <input type="checkbox"
                  v-model="form.show_nftlist"
                >
                {{ $t('NFT_LISTING') }}
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ種類：一覧表示 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑「ページ種類」セクション ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓「階層」セクション ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              class="!mb-0"
              title="HIERARCHY"
              :dataSource="form"
              :columns="arrayHierarchyFiltered"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓「カテゴリ」選択コンボボックス ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="category">
                <a-select
                  :value="this.category_id"
                  @change="onCategoryChange"
                  class="w-80"
                >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ コンボボックスの右端の▼ ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ コンボボックスの右端の▼ ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓「カテゴリ」選択コンボボックスの複数選択肢 ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                    v-for="category in this.arrayCategories"
                    :value="category.value"
                    :key="category.key"
                  >
                    {{ category.key }}
                  </a-select-option>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑「カテゴリ」選択コンボボックスの複数選択肢 ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </a-select>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑「カテゴリ」選択コンボボックス ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓「プロジェクト」選択コンボボックス ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="project">
                <a-select
                  :value="this.creator_id"
                  @change="onCreatorChange"
                  class="w-80"
                >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ コンボボックスの右端の▼ ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ コンボボックスの右端の▼ ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓「プロジェクト」選択コンボボックスの複数選択肢 ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                    v-for="creator in this.arrayCreators"
                    :value="creator.value"
                    :key="creator.key"
                  >
                    {{ creator.key }}
                  </a-select-option>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑「プロジェクト」選択コンボボックスの複数選択肢 ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </a-select>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑「プロジェクト」選択コンボボックス ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓「シリーズ」選択コンボボックス 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="series">
                <a-select
                  :value="this.series_id"
                  @change="onSeriesChange"
                  class="w-80"
                >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓ コンボボックスの右端の▼ ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <span
                    slot="suffixIcon"
                    class="text-4xl text-black relative -top-3.5 left-2"
                    >▾</span
                  >
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑ コンボボックスの右端の▼ ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↓「シリーズ」選択コンボボックスの複数選択肢 ↓ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <a-select-option
                    v-for="series in this.arraySeries"
                    :value="series.value"
                    :key="series.key"
                  >
                    {{ series.key }}
                  </a-select-option>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <!-- ↑「シリーズ」選択コンボボックスの複数選択肢 ↑ -->
                  <!-- ------+---------+---------+---------+---------+------ -->
                </a-select>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑「シリーズ」選択コンボボックス 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑「階層」セクション ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓「ページ内容」セクション ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              class="!mb-0"
              title="DETAIL_OF_PAGE"
              :dataSource="form"
              :columns="arrayDetailOfPage"
            >
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：プロジェクト画像アップロード 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="banner_url"
                value=""
                class="flex items-center"
              >
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="BANNER_URL"
                >
                  <div class="flex items-center">
                    <BaseUploader
                      v-model="form.banner"
                      id="banner"
                      suggestSizeText=""
                    />
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div v-if="!form.banner">
                      <a
                        class="text-xl font-semibold"
                        target="_blank"
                        download
                        :href="categoryDetail.banner_url"
                        >{{ categoryDetail.banner_uploaded_name }}</a
                      >
                    </div>
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="banner_url"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：プロジェクト画像アップロード 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：一覧表示用画像アップロード 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div
                slot="thumbnail_url"
                value=""
                class="flex items-center"
              >
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="THUMBNAIL_URL"
                >
                  <div class="flex items-center">
                    <BaseUploader
                      v-model="form.thumbnail"
                      id="thumbnail"
                      suggestSizeText=""
                    />
                    <!-- ----+---------+---------+---------+---------+------ -->
                    <div v-if="!form.thumbnail">
                      <a
                        class="text-xl font-semibold"
                        target="_blank"
                        download
                        :href="categoryDetail.thumbnail_url"
                        >{{ categoryDetail.thumbnail_uploaded_name }}</a
                      >
                    </div>
                  </div>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="thumbnail_url"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：一覧表示用画像アップロード 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：名称 (en) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="name">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="CATEGORY_NAME"
                >
                  <a-input
                    v-model="form.name_en"
                    :maxLength="100"
                    :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 100 })"
                  />
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="name"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div slot="label_name">{{ $t('CATEGORY_NAME_LABEL') }}&nbsp;(en)</div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：名称(en) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：名称 (ja) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="name_ja">
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="CATEGORY_NAME_JAPANESE"
                >
                  <a-input
                    v-model="form.name_ja"
                    :maxLength="100"
                    :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 100 })"
                  />
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="name_ja"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <div slot="label_name_ja">{{ $t('CATEGORY_NAME_LABEL') }}&nbsp;(ja)</div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：名称 (ja) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：紹介文 (en) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="description">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="CATEGORY_DESCRIPTION"
                >
                  <Editor
                    v-model="form.description_en"
                    :maxLength="2400"
                  ></Editor>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="description"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：紹介文(en) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="label_description">{{ $t('CATEGORY_DESCRIPTION_LABEL') }}&nbsp;(en)</div>
              <div slot="label_description_ja">{{ $t('CATEGORY_DESCRIPTION_LABEL') }}&nbsp;(ja)</div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：紹介文(ja) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="description_ja">
                <ValidationProvider
                  rules=""
                  v-slot="{ errors }"
                  name="CATEGORY_DESCRIPTION_JAPANESE"
                >
                  <Editor
                    v-model="form.description_ja"
                    :maxLength="2400"
                  ></Editor>
                  <!-- ------+---------+---------+---------+---------+------ -->
                  <ErrorMessage
                    id="description_ja"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：紹介文 (ja) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：TOP 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="recommendation_top_as_project">
                <a-checkbox
                  v-model="form.recommendation_top_as_project"
                  >{{ $t('PROJECT_DISPLAY_AS_RECOMMENDED_ON_TOP') }}</a-checkbox
                >
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：TOP 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：カテゴリTOP 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="recommendation_categorytop_as_project">
                <a-checkbox
                  v-model="form.recommendation_categorytop_as_project"
                  >{{ $t('PROJECT_DISPLAY_AS_RECOMMENDED_ON_CATEGORY') }}</a-checkbox
                >
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：カテゴリTOP 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：リンク 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="link">
                <a-input
                  v-model="form.link"
                  :maxLength="500"
                  :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 20 })"
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：リンク 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：カルーセル見出し (en) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="carousel_title_en">
                <a-input
                  v-model="form.carousel_title_en"
                  :maxLength="100"
                  :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 20 })"
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：カルーセル見出し (en) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：カルーセル見出し (ja) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="carousel_title_ja">
                <a-input
                  v-model="form.carousel_title_ja"
                  :maxLength="100"
                  :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 20 })"
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：カルーセル見出し (en) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：リスト見出し (en) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="list_title_en">
                <a-input
                  v-model="form.list_title_en"
                  :maxLength="100"
                  :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 20 })"
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：リスト見出し (en) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：リスト見出し (ja) 行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="list_title_ja">
                <a-input
                  v-model="form.list_title_ja"
                  :maxLength="100"
                  :placeholder="$t('PROJECT_MAX_CHARACTER_PLACEHOLDER', { max: 20 })"
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：リスト見出し (en) 行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↓ ページ内容：wordpress_link行 ↓ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
              <div slot="wordpress_link">
                <a-input
                  v-model="form.wordpress_link"
                  :maxLength="200"
                  :placeholder="$t('Wordpress Link', { max: 200 })"
                />
              </div>
              <!-- +---------+---------+---------+---------+---------+------ -->
              <!-- ↑ ページ内容：wordpress_link行 ↑ -->
              <!-- +---------+---------+---------+---------+---------+------ -->
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑「ページ内容」セクション ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↓「ステータス」セクション ↓ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <ProjectViewSection
              :dataSource="form"
              title="PROJECT_STATUS_SECTION_TITLE"
              :columns="arrayStatus"
            >
              <div slot="is_deleted" class="text-xl font-semibold">
                <div class="toggle-btn">
                  <div
                    @click="set_is_deleted(false)"
                    :class="{ 'toggle-btn__active': !form.is_deleted }"
                    :disabled="loading"
                  >
                    {{ $t('PROJECT_STATUS_SHOW_RADIO_BTN') }} <!--「表示」-->
                  </div>
                  <div
                    @click="set_is_deleted(true)"
                    :class="{ 'toggle-btn__active': form.is_deleted }"
                    :disabled="loading"
                  >
                    {{ $t('PROJECT_STATUS_HIDE_RADIO_BTN') }} <!--「非表示」-->
                  </div>
                </div>
              </div>
            </ProjectViewSection>
            <!-- --+---------+---------+---------+---------+---------+------ -->
            <!-- ↑「ステータス」セクション ↑ -->
            <!-- --+---------+---------+---------+---------+---------+------ -->
          </section>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↓「保存」ボタン 行 ↓ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <div class="w-full text-center bg-section-bg py-8">
            <button
              class="bg-primary text-white hover:opacity-60 text-2xl font-semibold py-2 px-12 rounded-xl"
              type="submit"
              :disabled="loading"
            >
              {{ $t('PROJECT_SAVE_BTN') }}
            </button>
          </div>
          <!-- ----+---------+---------+---------+---------+---------+------ -->
          <!-- ↑「保存」ボタン 行 ↑ -->
          <!-- ----+---------+---------+---------+---------+---------+------ -->
        </form>
      </ValidationObserver>
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↓「ユーザーロールが有効ではありませ」メッセージ ↓ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <div
      v-else
      class="mt-20 text-2xl font-bold text-center"
    >
      {{ $t('PROJECT_INVALID_ROLE_NOTIFICATION') }}
    </div>
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
    <!-- ↑「ユーザーロールが有効ではありませ」メッセージ ↑ -->
    <!-- +---------+---------+---------+---------+---------+---------+------ -->
  </div>
</template>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<script>
import ProjectViewSection from '@/components/project-management/ProjectViewSection';
import BaseUploader from '@/components/common/BaseUploader.vue';
import Editor from '@/components/common/Editor.vue';
// ------+---------+---------+---------+---------+---------+---------+---------E
import ErrorMessage from '@/components/common/ErrorMessage.vue';
import { getCategories, editCategory } from '@/services/categories';
import UtilNotify from '@/utils/notify.util';
import { mapGetters, mapState, mapActions } from 'vuex';
// ======+=========+=========+=========+=========+=========+=========+=========E
const arrayType = [
  // ページ種類の下位項目「種類」
  {
    key: 'category_type',
    title: 'CATEGORY_TYPE', // 「種類」（カテゴリ・プロジェクト・シリーズ・エディション）
    scopedSlots: { value: 'category_type' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // ページ種類の下位項目「一覧表示」
  {
    key: 'show_nftlist',
    title: 'NFT_LIST', // 「一覧表示」
    scopedSlots: { value: 'show_nftlist' },
  },
]; // End of arrayType = [...]
// ======+=========+=========+=========+=========+=========+=========+=========E
const arrayHierarchy = [
  // カテゴリ選択行
  {
    key: 'category',
    title: 'CATEGORY_CATEGORY_LABEL',
    scopedSlots: { value: 'category' },
  },
  // プロジェクト（クリエイター）選択行
  {
    key: 'project',
    title: 'PROJECT_PROJECT_LABEL',
    scopedSlots: { value: 'project' },
  },
  // シリーズ選択行
  {
    key: 'series',
    title: 'SERIES_SERIES_LABEL',
    scopedSlots: { value: 'series' },
  },
]; // End of const arrayHierarchy = [...]
// ======+=========+=========+=========+=========+=========+=========+=========E
const arrayDetailOfPage = [
  {
    key: 'banner_url',
    title: 'BANNER_IMG_UPLOAD_LABEL', // 「バナー画像アップロード」
    scopedSlots: { value: 'banner_url' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'thumbnail_url',
    title: 'PROJECT_LISTING_IMG_LABEL', // 「一覧表示用画像アップロード」
    scopedSlots: { value: 'thumbnail_url' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'name',
    title: 'PROJECT_NAME_LABEL', // 「名称」
    scopedSlots: { value: 'name', title: 'label_name' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'name_ja',
    title: 'PROJECT_NAME_LABEL', // 「名称」
    scopedSlots: { value: 'name_ja', title: 'label_name_ja' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'description',
    title: 'PROJECT_DESCRIPTION_LABEL', // 「プロジェクト概要」
    scopedSlots: { value: 'description', title: 'label_description' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'description_ja',
    title: 'PROJECT_DESCRIPTION_LABEL', // 「プロジェクト概要」
    scopedSlots: { value: 'description_ja', title: 'label_description_ja' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'recommendation_top_as_project',
    title: 'TOP', // 「TOP」
    scopedSlots: { value: 'recommendation_top_as_project' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'recommendation_categorytop_as_project',
    title: 'PROJECT_CATEGORY_TOP_LABEL', // 「カテゴリTOP」
    scopedSlots: { value: 'recommendation_categorytop_as_project' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'link',
    title: 'CATEGORY_LINK_LABEL',  // 「リンク」
    scopedSlots: { value: 'link' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'carousel_title_en',
    title: 'CATEGORY_CAROUSEL_TITLE_EN_LABEL',  // 「カルーセル見出し(en)」
    scopedSlots: { value: 'carousel_title_en' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'carousel_title_ja',
    title: 'CATEGORY_CAROUSEL_TITLE_JA_LABEL',  // 「カルーセル見出し(ja)」
    scopedSlots: { value: 'carousel_title_ja' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'list_title_en',
    title: 'CATEGORY_LIST_TITLE_EN_LABEL',  // 「リスト見出し(en)」
    scopedSlots: { value: 'list_title_en' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'list_title_ja',
    title: 'CATEGORY_LIST_TITLE_JA_LABEL',  // 「リスト見出し(ja)」
    scopedSlots: { value: 'list_title_ja' },
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    key: 'wordpress_link',
    title: 'CATEGORY_WORDPRESS_LINK_LABEL',  // 「wordpress_link」
    scopedSlots: { value: 'wordpress_link' },
  },
]; // End of const arrayDetailOfPage = [...]
// ======+=========+=========+=========+=========+=========+=========+=========E
const arrayStatus = [
  {
    key: 'is_deleted',
    title: 'PROJECT_STATUS_SHOW_RADIO_BTN', //「表示」
    scopedSlots: { value: 'is_deleted' },
  },
]; // End of arrayStatus = [...]
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  name: 'CategoryDetailPage',
  props: {},
  components: {
    Editor,
    ProjectViewSection,
    BaseUploader,
    ErrorMessage,
  }, // End of components: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  data() {
    return {
      arrayType,
      arrayHierarchy,
      arrayDetailOfPage,
      arrayStatus,
      loading: false,
      // +---------+---------+---------+---------+---------+---------+---------E
      category_id: 0,
      creator_id : 0,
      series_id  : 0,
      edition_id : 0,
      arrayCategories: [],        // カテゴリコンボボックスの選択肢
      objectCategoryIdNameEn: {}, // カテゴリidと英語カテゴリ名を紐づけるオブジェクト
      objectCategoryNameEnId: {}, // 英語カテゴリ名とカテゴリidを紐づけるオブジェクト
      // プロジェクト（漫画家、アーティスト）コンボボックスの選択肢
      arrayCreators: [],
      // プロジェクト（漫画家、アーティスト）idと
      // 英語プロジェクト（漫画家、アーティスト）名を紐づけるオブジェクト
      objectCreatorIdNameEn: {},
      // 英語プロジェクト（漫画家、アーティスト）名と
      // プロジェクト（漫画家、アーティスト）idを紐づけるオブジェクト
      objectCreatorNameEnId: {},
      arraySeries: [],            // シリーズコンボボックスの選択肢
      objectSeriesIdNameEn: {},   // シリーズidと英語シリーズ名を紐づけるオブジェクト     
      objectSeriesNameEnId: {},   // 英語シリーズ名とシリーズidを紐づけるオブジェクト     
      arrayEditions: [],          // エディションコンボボックスの選択肢
      objectEditionIdNameEn: {},  // エディションidと英語シリーズ名を紐づけるオブジェクト     
      objectEditionNameEnId: {},  // 英語エディション名とエディションidを紐づけるオブジェクト     
      // +---------+---------+---------+---------+---------+---------+---------E
      form: {
        type             : '',
        hierarchy        : '',
        link             : '',
        name_en          : '',
        name_ja          : '',
        thumbnail_url    : null,
        banner_url       : null,
        description_en   : '',
        description_ja   : '',
        recommendation_top_as_project: false, // 「TOP」チェックボックスの値
        recommendation_categorytop_as_project: false, // 「カテゴリTOP」チェックボックスの値
        show_nftlist     : false,
        carousel_title_en: '',
        carousel_title_ja: '',
        list_title_en    : '',
        list_title_ja    : '',
        wordpress_link   : '',
        is_deleted       : false, // 「ステータス」の表示／非表示ボタン
      }, // End of form: {...}
    }; // End of return {...}
  }, // End of data() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  computed: {
    ...mapGetters({
      userRole: 'wallet/loggedUserRole',
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    ...mapState({
      locale: (state) => state?.setting.locale,
      categoryDetailMap: (state) => state.categories.categoryDetailMap,
    }),
    // --+---------+---------+---------+---------+---------+---------+---------E
    arrayHierarchyFiltered() {
      let result;
      // 種類「カテゴリ」のレコードを編集する。
      // クリエイター・シリーズ・エディションのコンボボックスは表示しない。
      if (this.form.type == 0) { result = [] }
      // 種類「クリエイター」のレコードを編集する。
      // カテゴリのコンボボックスを表示する。
      if (this.form.type == 1) {
        result = arrayHierarchy.filter(
          (el) => el.key==='category'
        )
      } // End of if (this.form.type == 1) {...}
      // 種類「シリーズ」のレコードを編集する。
      // カテゴリ・クリエイターのコンボボックスを表示する。
      if (this.form.type == 2) {
        result =  arrayHierarchy.filter(
          (el) => el.key==='category' || el.key==='project'
        )
      } // End of if (this.form.type == 2) {...}
      // 種類「エディション」のレコードを編集する。
      // カテゴリ・クリエイター・シリーズのコンボボックスを表示する。
      if (this.form.type == 3) {
        result = arrayHierarchy
      }
      return result;
    }, // End of arrayHierarchyFiltered() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    categoryDetail() {
      const { id } = this.$route.params;
      console.debug({ id, categoryDetailMap: this.categoryDetailMap });
      if (id) {
        const data = this.categoryDetailMap[id];
        console.debug({ data });
        return data;
      } // End of if (id) {...}
      return {};
    }, // End of categoryDetail() {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // カテゴリの種類を選択するコンボボックスの選択肢。
    //（カテゴリ・プロジェクト（クリエイター）・シリーズ・エディション）
    typesOfCategory() {
      let resultArray;
      if (this.locale == 'en') {
        resultArray = [
          {key: 'category', value: 0}, {key: 'project', value: 1},
          {key: 'series'  , value: 2}, {key: 'edition', value: 3},
        ]
      } else {
        resultArray = [
          {key: 'カテゴリ', value: 0}, {key: 'プロジェクト', value: 1},
          {key: 'シリーズ', value: 2}, {key: 'エディション', value: 3},
        ] 
      } // End of if (this.locale == 'en') {...} else {...}
      return resultArray;
    }, // End of typesOfCategory() {...}
  }, // End of computed: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  watch: {
    categoryDetail(detail) {
      // const clonedFormData = { ...this.form };
      if (detail) {
        // this.form = {
        //   ...clonedFormData,
        // };
        // バックエンドから上がってきた情報に対する処理。
        this.form.type              = this.categoryDetail.type;
        this.form.hierarchy         = this.categoryDetail.hierarchy;
        this.form.link              = this.categoryDetail.link;
        this.form.name_en           = this.categoryDetail.name_en;
        this.form.name_ja           = this.categoryDetail.name_ja;
        this.form.description_en    = this.categoryDetail.description_en;
        this.form.description_ja    = this.categoryDetail.description_ja;
        this.form.recommendation_top_as_project =
          (this.categoryDetail.recommendation_top_as_project == 1);
        this.form.recommendation_categorytop_as_project =
          (this.categoryDetail.recommendation_categorytop_as_project == 1);
        this.form.show_nftlist      = (this.categoryDetail.show_nftlist == 1);
        this.form.carousel_title_en = this.categoryDetail.carousel_title_en;
        this.form.carousel_title_ja = this.categoryDetail.carousel_title_ja;
        this.form.list_title_en     = this.categoryDetail.list_title_en;
        this.form.list_title_ja     = this.categoryDetail.list_title_ja;
        this.form.wordpress_link    = this.categoryDetail.wordpress_link;
        this.form.is_deleted        = (this.categoryDetail.is_deleted == 1);
        // alert(`this.categoryDetail=${JSON.stringify(this.categoryDetail)}: categoryDetail: watch: edit-category.page.vue`);
        // alert(`this.form=${JSON.stringify(this.form)}: categoryDetail: watch`);
      } // End of if (detail) {...}
    }, // End of categoryDetail(detail) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // 言語が切り替わったら、コンボボックスの選択肢も言語に合わせて切り替える。
    locale: async function() {
      await this.updateComboBoxOptions()
    },
  }, // End of watch: {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  async mounted() {
    await this.updateComboBoxOptions()
  }, // End of mounted() {...}
  // ====+=========+=========+=========+=========+=========+=========+=========E
  created() {
    this.fetchCategoryDetailById();
  },
  // ====+=========+=========+=========+=========+=========+=========+=========E
  methods: {
    // コンボボックスの選択肢を更新。
    async updateComboBoxOptions() {
      await this.getCategories();
      await this.getCreators();
      await this.getSeries();
      await this.getEditions();
    }, // End of async updateComboBoxOptions() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // テーブル categories からカテゴリを取得する。カテゴリコンボボックスで使う。
    async getCategories() {
      const { arrayObjectOptions: arrayObjectOptions,
              objectIdNameEn    : objectIdNameEn,
              objectNameEnId    : objectNameEnId,
      } = await this.getFromTableCategories(0, undefined);
      this.arrayCategories = arrayObjectOptions;
      this.objectCategoryIdNameEn = objectIdNameEn;
      this.objectCategoryNameEnId = objectNameEnId;
    }, // End of async getCategories() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // テーブル categories からプロジェクト（漫画家、アーティスト）を取得する。
    // プロジェクト（漫画家、アーティスト）コンボボックスで使う。
    async getCreators() {
      // alert(`this.objectCategoryIdNameEn=${JSON.stringify(this.objectCategoryIdNameEn)}`);
      // alert(`this.category_id=${JSON.stringify(this.category_id)}`);
      const { arrayObjectOptions: arrayObjectOptions,
              objectIdNameEn    : objectIdNameEn,
              objectNameEnId    : objectNameEnId,
      } = await this.getFromTableCategories(1,
                          this.objectCategoryIdNameEn[this.category_id]
      );
      this.arrayCreators = arrayObjectOptions;
      this.objectCreatorIdNameEn = objectIdNameEn;
      this.objectCreatorNameEnId = objectNameEnId;
      // alert(`this.arrayCreators=${JSON.stringify(this.arrayCreators)}: getCreators()`);
      // alert(`this.objectCreatorIdNameEn=${JSON.stringify(this.objectCreatorIdNameEn)}: getCreators()`);
      // alert(`this.objectCreatorNameEnId=${JSON.stringify(this.objectCreatorNameEnId)}: getCreators()`);
      // alert(`END  : getCreators()`);
    }, // End of async getCreators() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // テーブル categories からシリーズを取得する。
    // シリーズコンボボックスで使う。
    async getSeries() {
      const { arrayObjectOptions: arrayObjectOptions,
              objectIdNameEn    : objectIdNameEn,
              objectNameEnId    : objectNameEnId,
      } = await this.getFromTableCategories(
        2,
        this.objectCategoryIdNameEn[this.category_id] + '\\' +
        this.objectCreatorIdNameEn[ this.creator_id]
      );
      this.arraySeries = arrayObjectOptions;
      this.objectSeriesIdNameEn = objectIdNameEn;
      this.objectSeriesNameEnId = objectNameEnId;
      // alert(`this.arraySeries=${JSON.stringify(this.arraySeries)}: getSeries()`);
      // alert(`this.objectSeriesIdNameEn=${JSON.stringify(this.objectSeriesIdNameEn)}: getSeries()`);
      // alert(`this.objectSeriesNameEnId=${JSON.stringify(this.objectSeriesNameEnId)}: getSeries()`);
    }, // End of async getSeries() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // テーブル categories から、カテゴリ、プロジェクト（漫画家、アーティスト）、
    // シリーズ、又は、エディションを取得する。コンボボックスで使う。
    async getFromTableCategories(intType, strHierarchy) {
      // alert(`this.locale=${this.locale}: getFromTableCategories()`);
      // alert(`intType=${intType}, strHierarchy=${strHierarchy}: getFromTableCategories()`);
      const {items: arrayObjectRecordCategories} =
        await getCategories({
          // type  0:カテゴリ、1:プロジェクト（漫画家、アーティスト）
          //       2:シリーズ
          type:  intType,
          hierarchy: strHierarchy,
        }); // End of await getCategories({...})
      // +---------+---------+---------+---------+---------+---------+---------E
      // コンボボックス用のカテゴリのオブジェクト配列を作成。
      // 配列の最初に、指定無しを表す選択肢を入れる。
      let arrayObject = [{key: this.strUndefined(), value: 0}];
      // 同時に id から name_en が得られるオブジェクトも作成。
      let objectIdNameEn = {0: ''}; // 指定無しで、id=0, value=""
      // 同時に name_en から id が得られるオブジェクトも作成。
      let objectNameEnId = {};
      for (const objectRecordCategory of arrayObjectRecordCategories) {
        let objectCategory = {}; // カテゴリのオブジェクト配列の一要素
        // --------+---------+---------+---------+---------+---------+---------E
        const strCategoryName = (
          this.locale === 'en'  ? objectRecordCategory.name_en
                                : objectRecordCategory.name_ja
        );
        // --------+---------+---------+---------+---------+---------+---------E
        objectCategory['key'  ] = strCategoryName;
        objectCategory['value'] = objectRecordCategory.id;
        // --------+---------+---------+---------+---------+---------+---------E
        arrayObject.push(objectCategory);
        objectIdNameEn[objectRecordCategory.id] = objectRecordCategory.name_en;
        objectNameEnId[objectRecordCategory.name_en] = objectRecordCategory.id;
      } // End of for (const objectRecordCategory of ...) {...}
      // +---------+---------+---------+---------+---------+---------+---------E
      let objectResult = {};
      objectResult['arrayObjectOptions'] = arrayObject;
      objectResult['objectIdNameEn'    ] = objectIdNameEn;
      objectResult['objectNameEnId'    ] = objectNameEnId;
      return objectResult;
    }, // End of async getFromTableCategories() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // カテゴリの種類（カテゴリ、プロジェクト、シリーズ、エディション）の
    // コンボボックスを変更したときの処理。
    async onTypeOfCategoryChange(value) {
      this.form.type = value;
      // カテゴリ選択肢を作る。
      await this.getCategories();
      // プロジェクト、シリーズのコンボボックスに「指定なし」の選択肢を入れる。
      this.arrayCreators   = [{key: this.strUndefined(), value: 0}];
      this.arraySeries     = [{key: this.strUndefined(), value: 0}];
      // カテゴリ以下コンボボックスの選択を「指定なし」にする。
      this.category_id = 0; // コンボボックスの選択を「指定なし」にする。
      this.creator_id  = 0; // コンボボックスの選択を「指定なし」にする。
      this.series_id   = 0; // コンボボックスの選択を「指定なし」にする。
      // hierarchy クリア。
      this.form.hierarchy = undefined;
    }, // End of onTypeOfCategoryChange(value) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // カテゴリのコンボボックスが使われた時に実行する関数。
    async onCategoryChange(value) {
      // alert(`START: onCategoryChange(value=${value})`);
      this.category_id = value;
      // コンボボックスに「指定なし」の選択肢を入れる。
      this.arrayCreators = [{key: this.strUndefined(), value: 0}];
      this.arraySeries   = [{key: this.strUndefined(), value: 0}];
      // サブカテゴリ以下はコンボボックスの選択を「指定なし」にする。
      this.creator_id = 0; // コンボボックスの選択を「指定なし」にする。
      this.series_id  = 0; // コンボボックスの選択を「指定なし」にする。
      // hierarchy クリア。
      this.form.hierarchy = undefined;
      if (value == 0) { return } // 「指定無し」選択された場合。
      // カテゴリが選択された。
      // hierarchy 作成。
      this.form.hierarchy = this.objectCategoryIdNameEn[this.category_id];
      // alert(`this.form.hierarchy=${this.form.hierarchy}: onCategoryChange`);
      // プロジェクト（漫画家、アーティスト） 選択肢作成。
      await this.getCreators();
      // alert(`END  : onCategoryChange`);
    }, // End of async onCategoryChange(value) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // 「プロジェクト（漫画家、アーティスト）」のコンボボックスが使われた時
    // に実行する関数。
    async onCreatorChange(value) {
      this.creator_id = value;
      // コンボボックスに「指定なし」の選択肢を入れる。
      this.arraySeries   = [{key: this.strUndefined(), value: 0}];
      // サブカテゴリ以下はコンボボックスの選択を「指定なし」にする。
      this.series_id  = 0; // コンボボックスの選択を「指定なし」にする。
      // hierarchy の上位カテゴリ部分まで作成。
      this.form.hierarchy = this.objectCategoryIdNameEn[this.category_id];
      if (value == 0) { return } // 「指定無し」選択された場合。
      // クリエイターが選択された。
      // hierarchy のクリエイター部分追加。
      this.form.hierarchy = this.form.hierarchy + '\\' +
                            this.objectCreatorIdNameEn[ this.creator_id ];
      // alert(`this.form.hierarchy=${this.form.hierarchy}: onCreatorChange`);
      // シリーズ選択肢作成。
      await this.getSeries();
    }, // End of async onCreatorChange(value) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // 「シリーズ」のコンボボックスが使われた時に実行する関数。
    async onSeriesChange(value) {
      this.series_id = value;
      // hierarchy の上位カテゴリ部分まで作成。
      this.form.hierarchy =
        this.objectCategoryIdNameEn[this.category_id] + '\\' +
        this.objectCreatorIdNameEn[ this.creator_id ];
      if (value == 0) { return } // 「指定無し」選択された場合。
      // シリーズが選択された。
      // hierarchy 作成。
      this.form.hierarchy = this.form.hierarchy + '\\' +
                            this.objectSeriesIdNameEn[  this.series_id  ];
    // (`this.form.hierarchy=${this.form.hierarchy}: onSeriesChange`);
    }, // End of async onSeriesChange(value) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // コンボボックス選択肢用の「--- Undefined ---」「--- 指定無し ---」。
    // 英語と日本語で切り替える。
    strUndefined() {
      let result = (this.locale === 'en'  ? '--- Undefined ---'
                                          : '--- 指定無し ---');
      return result;
    }, // End of strUndefined() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    getLink(link) {
      if (link.includes('https://') || link.includes('http://')) {
        return link;
      } else {
        return `https://${link}`;
      } // End of if (link.includes('https://') || ...) {...}
    }, // End of getLink(link) {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    ...mapActions({
      fetchCategoryDetail: 'categories/fetchCategoryDetail',
    }),
    // ---+--------+---------+---------+---------+---------+---------+---------E
    async fetchCategoryDetailById() {
      // alert(`START: fetchCategoryDetailById`);
      const { id } = this.$route.params;
      await this.fetchCategoryDetail(id);
      // 以下、hierarchy からコンボボックスに現在値をセットするための処理。
      // +---------+---------+---------+---------+---------+---------+---------E
      if (!this.categoryDetail.hierarchy) { return; } // hierarchy空で終了。
      // +---------+---------+---------+---------+---------+---------+---------E
      // hierarchy に値が有る。
      const arrayHierarchy = this.categoryDetail.hierarchy.split('\\');
      if (!arrayHierarchy[0]) { return } // カテゴリ指定が無いので終了。
      // --------+---------+---------+---------+---------+---------+---------E
      // カテゴリ指定がある。
      await this.getCategories(); // カテゴリ選択肢を作るためここで実行。
      this.category_id = this.objectCategoryNameEnId[arrayHierarchy[0]];
      await this.getCreators(); // クリエイター選択肢を作るためここで実行。
      // --------+---------+---------+---------+---------+---------+---------E
      if (!arrayHierarchy[1]) { return } // クリエイター指定が無いので終了。
      // --------+---------+---------+---------+---------+---------+---------E
      // クリエイター指定がある。
      this.creator_id = this.objectCreatorNameEnId[arrayHierarchy[1]];
      // alert(`this.creator_id=${this.creator_id}: fetchCategoryDetailById`);
      await this.getSeries(); // シリーズ選択肢を作るためここで実行。
      // --------+---------+---------+---------+---------+---------+---------E
      if (!arrayHierarchy[2]) { return } // シリーズ指定が無いので終了。
      // --------+---------+---------+---------+---------+---------+---------E
      // シリーズ指定がある。
      this.series_id = this.objectSeriesNameEnId[arrayHierarchy[2]];
      // alert(`this.series_id=${this.series_id}: fetchCategoryDetailById`);
      // --------+---------+---------+---------+---------+---------+---------E
      // alert(`END  : fetchCategoryDetailById`);
    }, // End of async fetchCategoryDetailById() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    // カテゴリレコードの is_deleted に値セットする。
    set_is_deleted(setValue) {
      this.form.is_deleted = setValue;
    }, // End of set_is_deleted() {...}
    // ---+--------+---------+---------+---------+---------+---------+---------E
    async onSubmitForm() {
      // alert(`START: onSubmitForm: methods: edit-category.page.vue`);
      try {
        const isValid = await this.$refs.observer.validate();
        console.debug({
          isValid,
        }); // End of console.debug({...})
        if (!isValid) return;
        if (this.loading) return;
        this.loading = true;
        // --------+---------+---------+---------+---------+---------+---------E
        const form = {
          ...this.form,
          banner   : this.form.banner?.file,
          thumbnail: this.form.thumbnail?.file,
        }; // End of const form = {...}
        // alert(`form=${JSON.stringify(form)}: onSubmitForm: methods: edit-category.page.vue`);
        const payload = new FormData();
        for (const property in form) {
          const data = form[property];
          if (data || data === 0) {
            payload.append(`${property}`, form[property]);
          }
        } // End of for (const property in form) {...}
        // alert(`payload=${[...payload.entries()]}: onSubmitForm: methods: edit-category.page.vue`);
        const data = await editCategory(this.categoryDetail.id, payload);
        // alert(`Just after editCategory: onSubmitForm: methods: edit-category.page.vue`);
        this.loading = false;
        // --------+---------+---------+---------+---------+---------+---------E
        if (data) {
          UtilNotify.success(this.$notify, { message: 'CATEGORY_EDIT_SUCCESSFUL_NOTIFICATION' });
          this.$router.push('/manage/categories');
        }
      } catch (error) {
        this.loading = false;
        // --------+---------+---------+---------+---------+---------+---------E
        console.error(error);
        UtilNotify.error(this.$notify, error);
      } // End of try {...} catch (error) {...}
    }, // End of async onSubmitForm() {...}
  }, // End of methods: {...}
}; // End of export default {...}
</script>
<!-- ====+=========+=========+=========+=========+=========+=========+====== -->
<style
  lang="scss"
  scoped
>
::v-deep .input-number-custom input {
  @apply px-2 rounded-none;
  @apply bg-white text-black text-lg;
  @apply h-8;
  @apply disabled:cursor-not-allowed;
}

::v-deep .ant-input {
  @apply border-none rounded-sm text-black text-lg;
}

::v-deep .ant-select {
  @apply font-semibold text-black;
  &-selection {
    background-color: #fff;
    @apply text-lg font-semibold;
    border: none;
    border-radius: 0px;
    &__rendered {
      // .ant-select-selection-selected-value {
      //   @apply relative left-1/2;
      //   transform: translateX(-50%);
      // }
    }
  }
}

/* ::v-deep .ant-radio-wrapper { */
/*   .ant-radio-disabled + span { */
/*     color: white !important; */
/*   } */
/* } */

::v-deep .ant-checkbox-wrapper {
  @apply text-white text-xl;
  .ant-checkbox {
    span {
      @apply text-xl text-white;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      @apply border-white bg-white;
      &::after {
        @apply border-primary;
      }
    }
    &::after {
      @apply border border-solid border-primary;
    }
  }
}

.w-150px {
  width: 150px;
}

/* is_deletedをセットするボタンに使用 */
.toggle-btn {
  @apply flex items-center w-max;
  border-radius: 14px;
  padding: 2px 4px;
  background-color: #21134d;
  div {
    border-radius: 14px;
    @apply px-6 py-2 font-semibold text-2xl;
    &:first-child {
      @apply mr-2;
    }
  }
  &__active {
    @apply bg-primary;
  }
}
</style>
